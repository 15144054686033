export default {
  "additionalQuestion": {
    "adultFemale": "여성",
    "adultMale": "남성",
    "adultOther": "기타",
    "answerQuestions": "다음 추가 질문에 답변해 주십시오.",
    "checkinTime": "체크인 시간",
    "checkoutTime": "체크아웃 시간",
    "moreDetailed": "투숙 관련 추가 정보가 필요합니다.",
    "notificationFromFacility": "시설 공지사항",
    "selectTime": "시간 선택",
    "view": "보기",
    "customizeYourStayExp": "나만의 맞춤형 숙박 경험을 계획해 보세요.",
    "customizeYourStayExpDesc": "추가 질문에 답변해 주시겠습니까?"
  },
  "turnstile": {
    "unsupportedBrowser": "현재 사용 중인 브라우저는 지원되지 않습니다. Chrome, Firefox, Safari 또는 Microsoft Edge를 사용해 주세요."
  },
  "base": {
    "account": "계정",
    "all": "모두",
    "apply": "적용",
    "attachFile": "파일첨부",
    "backTo": "{0}로 돌아가다",
    "booking": "예약",
    "browserWarning": "현재 이용하시는 브라우저는 지원하지 않습니다. 최신 브라우저를 설치하여, 다시 시도해 주시기 바랍니다.",
    "cancel": "취소",
    "close": "닫기",
    "confirm": "예약확정",
    "continue": "다음",
    "collapse": "축소",
    "delete": "삭제",
    "edit": "변경",
    "error": "죄송합니다. 시스템에 에러가 발생하였습니다. 잠시 후 다시 시도해 주시기 바랍니다.",
    "error02": "죄송합니다. 시스템에 에러가 발생하였습니다. 잠시 후 다시 시도해 주시기 바랍니다.",
    "errorPaymentMethod": "2개 이상의 객실 예약을 다른 결제 방법으로 진행할 수 없습니다. 진행을 위해서 동일한 결제 방식으로 진행하시거나 개별적으로 예약을 해주시기 바랍니다.",
    "oldErrorCode": {
      "1403": "해당 계정은 시설에 의해 등록되었지만 비밀번호를 재설정해야 합니다. 로그인 페이지로 돌아가서 「비밀번호가 기억나지 않습니다」를 누르십시오."
    },
    "errorCode": {
      "1501": "해당 쿠폰의 사용 한도에 도달하여 다시 적용할 수 없습니다.",
      "1903": "예약 가능한 기간은 오늘부터 {reservable_period_months}개월입니다. \n예약 가능한 기간을 지정하십시오.",
      "2304": "플랜 설정이 고객님의 계정 상태와 일치하지 않습니다. 페이지를 새로고침 후, 다시 예약해 주세요.",
      "2401": "현재 포인트 잔액은 {currentPoint} 입니다. {spendPoint} 포인트를 사용할 수 없습니다.",
      "3001": "시간 초과 에러가 발생하였습니다. 결제 처리는 진행되지 않았습니다.",
      "3002": "오류가 발생하여 결제 처리를 실패하였습니다. 번거로우시겠지만, 예약이 완료되지 않았으니 다시 시도해 주시기 바랍니다.",
      "3003": "이 카드는 사용하실 수 없습니다.",
      "3004": "카드번호를 확인바랍니다.",
      "3005": "만료일이 올바른지 확인하십시오.",
      "3006": "3D Secure 인증에 실패했습니다.",
      "3007": "이용할 수 없는 카드를 입력했거나 카드번호가 잘못되었습니다.",
      "3008": "3D Secure 인증이 취소되었습니다.",
      "3009": "중복 요청으로 인해 3D Secure 인증을 중단했습니다.",
      "3011": "시간 초과 에러가 발생하였습니다. 결제 처리는 진행되지 않았습니다.",
      "3013": "진행 중인 업데이트 또는 결제 처리로 인해 현재 해당 예약을 업데이트할 수 없습니다. 약 5분 후에 다시 시도해 주세요.",
      "3014": "원래 예약 내용이 업데이트되었으므로 일단 취소하고 새로운 내용을 다시 확인한 다음 업데이트하세요. ",
      "3016": "진행 중인 업데이트 또는 결제 처리로 인해 현재 해당 예약을 업데이트할 수 없습니다. 약 5분 후에 다시 시도해 주세요.",
      "3202": "포인트 잔액 정보가 없습니다.",
      "3203": "죄송합니다. 처리중 에러가 발생하였습니다. 잠시 후 다시 시도해 주시기 바랍니다.",
      "3204": "결제에 실패했습니다. 다른 카드를 사용하거나 나중에 다시 시도해주세요.",
      "3205": "이 카드는 사용하실 수 없습니다.",
      "3250": "3D Secure 인증에 실패했습니다.",
      "3251": "죄송합니다. 처리중 에러가 발생하였습니다. 잠시 후 다시 시도해 주시기 바랍니다.",
      "3252": "입력하신 카드는 3D 인증과 호환되지 않습니다.",
      "3502": "포인트 잔액 정보가 없습니다.",
      "3504": "포인트를 반환할 수 없습니다. 포인트 내역을 확인하십시오.",
      "3750": "해당 날짜는 추첨 이벤트에만 적용됩니다. 자세한 내용은 호텔 사이트에서 확인해 주세요.",
      "3751": "이 추첨 이벤트는 이용불가입니다. 자세한 내용은 호텔 사이트에서 확인해 주세요.",
      "3752": "이 추첨 이벤트는 이용불가입니다. 자세한 내용은 호텔 사이트에서 확인해 주세요.",
      "3753": "이 추첨 이벤트는 이용불가입니다. 자세한 내용은 호텔 사이트에서 확인해 주세요.",
      "3754": "이 추첨 이벤트는 이용불가입니다. 자세한 내용은 호텔 사이트에서 확인해 주세요.",
      "3756": "이미 추첨에 응모하셨습니다. 「예약」을 클릭한 후, 회원 프로필 페이지「추첨」에서 응모 여부를 확인하시기 바랍니다.",
      "3757": "이 추첨 이벤트는 이용불가입니다. 자세한 내용은 호텔 사이트에서 확인해 주세요.",
      "3760": "이 추첨 이벤트는 이용불가입니다. 자세한 내용은 호텔 사이트에서 확인해 주세요.",
      "3780": "링크가 만료되었거나 무효합니다. 호텔 사이트를 방문하여 이 추첨 이벤트에 다시 접속하세요.",
      "3781": "링크가 만료되었거나 무효합니다. 호텔 사이트를 방문하여 이 추첨 이벤트에 다시 접속하세요.",
      "3782": "링크가 만료되었거나 무효합니다. 호텔 사이트를 방문하여 이 추첨 이벤트에 다시 접속하세요.",
      "3783": "링크가 만료되었거나 무효합니다. 호텔 사이트를 방문하여 이 추첨 이벤트에 다시 접속하세요."
    },
    "sessionExpired": "세션이 만료되었습니다. 계속하려면 페이지를 새로고침하세요.",
    "notAvailableYet": "해당 작업은 아직 이용할 수 없습니다. 나중에 다시 시도해 주세요.",
    "fileLimit80MB": "이미지 당 최대 파일 업로드 사이즈는 80MB입니다.",
    "upTo30FilesError": "30개 이상의 파일을 첨부할 수 없습니다",
    "upTo30Files": "최대 30개 파일",
    "textLengthLimit": "답변 텍스트는 반드시 255자 이하로 입력해 주십시오.",
    "triplaCoLtd": "tripla(주)",
    "triplaAi": "tripla.ai",
    "GDPR": "개인정보 보호규정",
    "here": "여기",
    "imageError": "이미지를 불러오는 데 실패하였습니다.",
    "join": "회원가입",
    "languages": {
      "en": "English",
      "ja": "日本語",
      "ko": "한국어",
      "zh_Hans": "简体中文",
      "zh_Hant": "繁體中文"
    },
    "loading": "검색 중...",
    "search": "검색",
    "login": "로그인",
    "logout": "로그아웃",
    "logoutMessage": "이미 로그아웃되었습니다.",
    "signInStatusChangeMessage": "로그인 상태가 변경되었으므로, 검색 화면으로 이동합니다.",
    "memberSignIn": "멤버십 로그인",
    "memberSignUp": "멤버 첫 웹사이트 등록",
    "next": "다음",
    "notSelected": "선택되지 않음",
    "noData": "데이터 없음",
    "optional": "선택",
    "pay": "지불",
    "previous": "이전",
    "previousPage": "이전 페이지",
    "privacyPolicy": "개인정보 보호법",
    "readMore": "더 보기",
    "readLess": "접기",
    "recommended": "추천",
    "remove": "삭제",
    "required": "필수",
    "requireMessage": "필수항목은 반드시 입력하셔야 합니다.",
    "reservationStatuses": {
      "cancelled": "취소완료",
      "done": "예약완료"
    },
    "save": "저장",
    "signInUp": "로그인/회원등록",
    "submit": "제출",
    "termError": "진행하시려면 이용 약관에 동의해주세요.",
    "taxMessage": "일본의 소비세는 현재 8%입니다만,  2019년 10월을 기점으로 인상될 것으로 예상됩니다. 현재는 10월 이후의 예약에 대해서도 세금별도금액이 소비세 8%라는 전제로 표시됩니다. 번거롭게 해드려 죄송합니다만, 10월 이후의 숙박에 대해서는, 예약확인화면에서 세금포함금액을 정확한 금액으로서 참고바랍니다.",
    "termsOfUse": "이용규약",
    "topPage": "페이지 상단",
    "update": "업데이트",
    "success": "성공",
    "unsubscribe": "구독 취소",
    "unsubscribeConfirmation": {
      "error": "오류가 발생했습니다. 다시 시도해 주세요.",
      "title": "이메일 수신을 중지하시겠습니까?",
      "description": "프로모션 이메일 구독을 취소하려면 버튼을 클릭하세요."
    },
    "unsubscribeSuccessDescribe": "구독을 취소했습니다. 더 이상 프로모션 이메일을 보내지 않습니다.",
    "warning": {
      "kidsChanged": "해당 시설에서는 지정하신 검색 조건을 사용할 수 없어 어린이 검색 조건이 변경되었습니다."
    },
    "formalName": "{name}님",
    "maintenanceTitle": "현재 사이트 유지보수중입니다.",
    "maintenanceDesc": "불편을 끼쳐 드려 죄송합니다. 잠시 기다려 주시기 바랍니다.",
    "noResultAvailable": "일치하는 검색 결과가 없습니다",
    "taxAndServiceFeeIncluded": "세금 및 서비스 수수료 포함"
  },
  "roomLottery": {
    "updateLottery": "추첨 응모 변경하기",
    "restoreLottery": "추첨 응모 복원하기",
    "updateDisabledDesc": "해당 추첨의 응모 기간이 종료되었으니 결과를 기다려 주세요.\n",
    "viewUpdateDetails": "보기/정보 변경",
    "restoreUpdateEntry": "복원/응모 변경",
    "updateProfileDesc": "추첨에 당첨 후, 예약을 완료하시면 상기의 정보가 적용되며 변경이 불가합니다.",
    "cancellationEmailDesc": "{0}로 취소 이메일이 전송되었습니다.",
    "cancelModalTitle": "추첨 응모 취소",
    "cancelModalDesc": "추첨 응모를 취소하시겠습니까? 이전 상태로 되돌릴 수 없습니다.",
    "cancelEntry": "응모 취소하기",
    "reservationDeadlinePassed": "예약 기한이 지났습니다.",
    "reservationDeadlinePassedDesc": "죄송하지만, 예약 기한이 지났습니다. 자세한 내용은 호텔 웹사이트를 방문하거나 직접 문의하시기 바랍니다.",
    "accountNotMatched": "해당 계정은 추첨 응모 시 사용한 계정과 일치하지 않습니다. 올바른 계정으로 로그인하세요.",
    "resultDateNotice": "결과는 {date}({day}) {time} ~에 발표됩니다.",
    "resultDateNotice02": "당첨되신 분들께만 예약 절차 안내를 위한 추가 이메일이 발송됩니다.",
    "resultDateNotice03": "회원 메뉴의 「예약」에서 언제든지 확인할 수 있습니다.",
    "lotteryEntryNumber": "추첨 응모 번호",
    "lotteryEntryConfirmed": "추첨 응모가 확정되었습니다.",
    "lotteryEntryCancelled": "추첨 응모가 취소되었습니다.",
    "lotteryResultNotSelected": "아쉽게도 추첨 이벤트에서 탈락하셨습니다. ",
    "notSelectedNotice": "참여해 주셔서 감사합니다. 다음에 뵙기를 기대하고 있겠습니다.",
    "lotteryResultSelectedNotified": "🎉 당첨되셨습니다. ",
    "lotteryWinnerNotice01": "*아직 예약이 확정되지 않았습니다. 예약 절차를 완료해 주세요.",
    "lotteryWinnerNotice02": "예약 마감일 {date} ({day}) {time} 까지",
    "manageYourLottery": "추첨 관리",
    "reservationIncomplete": {
      "errorMessage": "다른 예약 처리가 진행 중입니다. 예약을 계속하면, 다른 예약 처리는 취소됩니다.",
      "checkboxMessage": "다른 예약 처리 취소에 동의합니다.",
      "modalMessage": "다른 예약 처리가 이미 완료되어, 예약 정보를 표시합니다."
    }
  },
  "bookingDetail": {
    "overlappedReservation": "현재 예약하신 체크인과 체크아웃 날짜가 중복된 {0}이 있습니다. 예약 전에 다시 확인하시기 바랍니다.\n*중복된 예약은 해당 브랜드의 다른 시설 예약의 경우가 있습니다.",
    "overlappedReservationLink": "예약",
    "overlappedReservationGuest": "현재 예약하신 체크인과 체크아웃 날짜가 중복된 예약이 있습니다. 예약 전에 다시 확인하시기 바랍니다.\r\n*중복된 예약은 해당 브랜드의 다른 시설 예약의 경우가 있습니다.\n※다른 기기에서 예약을 취소한 경우에는 해당 탐지가 잘못되었을 수 있으므로 수신한 이메일을 확인하십시오.",
    "overlappedReservationGuest2": "*다른 기기로 예약을 취소했거나 쿠키를 삭제한 경우 해당 탐지가 잘못되었을 수 있으므로 수신한 이메일로 확인하시기 바랍니다."
  },
  "bookingResult": {
    "paymentPromotionBanner": {
      "description": "이 할인은 신용카드로 결제를 완료한 예약에 한해 적용됩니다.",
      "message": "신용카드 결제 시 {0} 특별 할인 혜택을 누리세요!",
      "limitedTimeOnly": "이 혜택은 기간 한정 특가입니다.",
      "title2": "신용카드로 결제하고 할인 받으세요!",
      "title3": "신용카드 결제로 {0}엔 할인"
    },
    "allUser": "전체사용자",
    "availableBetween": "가능시간",
    "bath": "욕조",
    "shower": "샤워 ",
    "bathtub": "욕조 ",
    "bedCount": "{n}대",
    "book": "예약",
    "bookAirline": "항공권+객실",
    "flightRooms": "항공권+객실",
    "bookAirlineWarning": "{0} 옵션은 체크인 날짜 {1}에만 제공됩니다. 해당 옵션을 이용하려면 체크인 날짜를 조정하세요.",
    "bookAirlineWarningStay": "{0} 옵션은 최대 숙박 기간인 {1}일에만 제공됩니다. 해당 옵션을 이용하려면 숙박 기간을 조정하세요.",
    "cancellationFreeUntil": "{0}까지 무료취소",
    "cancellationPolicy": "취소정책",
    "discountAmount": "할인 금액",
    "excludeDuring": "제외기간",
    "forNights": "{n}박 요금",
    "freeWifi": "무료 WiFi",
    "followingSearchConditions": "아래 일정에는 공실이 있습니다.",
    "guests": "투숙인원수",
    "includes": "포함",
    "planInformation": "포함",
    "lowestPriceInfo": "최저 요금은 객실재고와 할인상태에 따라 캘린더에 노출되지 않을 수 있습니다. 실제 가격은 캘린더에서 확인하실 수 있습니다.",
    "maxAdults": "성인은 최대{n}명까지 이용 가능",
    "meals": "식사",
    "memberExclusive": "멤버한정",
    "membersOnly": "멤버전용",
    "nonSmoking": "금연",
    "noRoomFound": "죄송합니다. 현재 가능객실이 없습니다.",
    "tryChanging": "{0}나 위치를 변경 후 다시 시도해 주세요. ",
    "tryItem": "날짜",
    "noRoomSelected": "객실이 선택되지 않았습니다.",
    "noRoomsAvailable": "대단히 죄송합니다. 해당 조건에 맞는 공실이 없습니다.",
    "noRoomsAvailableDesc": "날짜 또는 시설을 변경하여 다시 시도해 주시기 바랍니다.",
    "notAvailable": "예약불가",
    "priceFor": "전체금액",
    "priceForAdult": "성인 × {n}명",
    "priceForChild": "어린이 × {n}명",
    "priceForRoom": "객실",
    "paymentCreditCard": "신용카드 온라인사전결제",
    "paymentMethods": "결제방법",
    "paymentMethod": {
      "on_site": "현지결제",
      "credit_card": "신용카드 온라인사전결제",
      "on_site_credit_card_required": "현지결제(사전 신용카드 등록필요)",
      "deferred_payment": "후불",
      "credit_card_full": "신용카드로 전액 결제",
      "credit_card_deposit": "신용카드로 보증금 결제",
      "bank_transfer": "은행 이체",
      "credit_card_krw_domestic": "온라인 결제 (한국 내 발급 신용카드)",
      "krw_domestic_description": "현재 롯데카드 결제는 불가하며 빠른 시일 내에 도입 예정입니다.",
      "credit_card_krw_international": "온라인 결제 (해외 신용카드)"
    },
    "paymentNewCreditCards": "새로운 신용카드로 결제",
    "paymentRegisteredCreditCards": "등록된 신용카드로 결제",
    "planDetails": "플랜 세부정보",
    "plansLimitForUpdating": "기존선택 플랜이외에는 변경하실 수 없습니다.",
    "price": "가격",
    "promotionMayChanged": "프로모션은 예고 없이 변경 또는 종료될 수 있습니다.",
    "registerCard": "해당카드등록",
    "regularRate": "일반가격",
    "reservationStartDate": "예약시작일",
    "showRoomDetails": "객실세부정보 보기",
    "roomDetails": "객실상세정보",
    "roomFeatures": "객실정보",
    "roomLeft": "잔여객실 {n}개",
    "roomAvailable": "나머지 {n}실",
    "roomEnough": "공실 있음",
    "roomRate": "요금",
    "rooms": "객실수",
    "roomSize": "객실사이즈",
    "roomSizeUnit": {
      "tatami_mats": "다다미사이즈(1조=0.5평)"
    },
    "select": "선택",
    "selectNewDate": "다른 날짜 선택",
    "serviceFeeDesc": "이 가격은 예약사이트의 각 서비스 요금이 포함된 가격입니다.",
    "sleeps": "투숙인원",
    "smoking": "흡연",
    "stopReservationUpdate": "예약변경을 중단",
    "stopLotteryEntryRestore": "추첨 복원 진행 중지",
    "stopLotteryEntryUpdate": "추첨 변경 진행 중지",
    "targetUser": "대상 사용자",
    "taxExcluded": "세금 미포함",
    "taxIncluded": "세금 포함",
    "toilet": "화장실",
    "totalPrice": "엔",
    "totalPriceForNights": "{n}박 전체요금 | {n}박 전체요금 | {n}박 전체요금",
    "viewAll": "전체보기",
    "viewClose": "닫기",
    "viewGallery": "전체사진보기",
    "viewLess": "닫기",
    "viewMore": "전체플랜보기 {n}건",
    "viewAllRooms": "{n}객실 전체보기",
    "wifi": "WiFi",
    "wifiOption1": "불가",
    "wifiOption2": "무료",
    "wifiOption3": "유료",
    "searchResults": "검색 결과:",
    "nPlans": "{n} 건",
    "nRoomTypes": "{n} 건"
  },
  "customAuth": {
    "failTitle": "로그인 실패.",
    "failDesc": "회원정보가 등록되어 있지 않아 로그인에 실패했습니다."
  },
  "cancellationPolicy": {
    "policyHeader": "해당 취소 정책이 본 예약에 반영됩니다.",
    "policyLine": "체크인 {0} 일전: 전체 요금의 {1}%",
    "policyLineWithTime": "체크인 {0}일전, {1}이후: 전체 요금의 {2}%",
    "freePolicyLine": "체크인 {0} 일전: 무료",
    "noShowLine": "노쇼: 전체 요금의 {0}%",
    "cancelOnTheDay": "체크인 당일: 전체 요금의 {0}%",
    "cancelOnTheDayFree": "체크인 당일 {0}시 이전 체크인 시: 무료",
    "cancelOnTheDayCharge": "체크인 당일 {0}시 이후 체크인 시: 전체 요금의 {1}%",
    "cancelBeforeOnTheDayCharge": "체크인 당일 {0}시 이전 체크인 시: 전체 요금의 {1}%",
    "uponLocalTime": "*모든 취소 정책은 현지 시간을 기준으로 적용됩니다.",
    "uponLocalTimeOTA": "모든 취소 정책은 현지 시간을 기준으로 적용됩니다.",
    "ifBookedElsewhere": "타사 사이트나 여행사를 통해서 하신 예약하신 건의 취소는 해당사로 문의해 주시기 바랍니다.",
    "specialRate": "특별 취소수수료: {0}",
    "guest": "고객",
    "policyWithTimeEachDayCharge": "체크인 {0}일 전, {1} 이후: 전체 요금의 {2}%  ",
    "policyWithBeforeTimeEachDayCharge": "체크인 {0}일 전, {1} 이전: 전체 요금의 {2}%",
    "policyWithTimeEachDayFree": "체크인 {0}일 전, {1} 이전: 무료"
  },
  "calendar": {
    "fullyBooked": "만실",
    "roomsLeft": "남은 객실수",
    "selected": "투숙일자",
    "unavailable": "공실없음",
    "unavailableNow": "지금은 사용 불가능합니다"
  },
  "corporateAdmin": {
    "admin": "관리자",
    "checkInDate": "체크인 날짜",
    "download": "다운로드",
    "guestName": "예약자명",
    "hotelName": "호텔명",
    "maxNumberStay": "최대 숙박일수",
    "maxNumberStays": "최대 숙박일수",
    "nMonths": "{n}개월",
    "nights": "숙박 일수",
    "numberOfRooms": "객실수",
    "remaining": "숙박가능일수",
    "reservationData": "예약정보",
    "reservationDataDesc1": "※ 이용 내역은 당사 홈페이지에서 예약하신 분의 합계로 숙박 수를 표시하고 있습니다. 따라서 다른 예약 (전화 등) 에 대해서는 반영되지 않으므로 참고하시기 바랍니다.",
    "reservationDataDesc2": "※ 예약 후 본 페이지에 반영까지는 시간이 걸릴 수 있습니다. 참고 부탁드립니다. ",
    "reservationDataDesc3": "※ 본 홈페이지를 통하지 않은 취소 또는 변경 내용은 반영되지 않을 수 있습니다.",
    "reservationDate": "예약일",
    "reservationNumber": "예약 번호",
    "reserved": "숙박완료일수",
    "status": "상태",
    "title": "기업 관리자",
    "totalPrice": "요금",
    "period": "기간",
    "year": "년"
  },
  "creditCardForm": {
    "enterYourPaymentDetails": "결제 정보를 입력하세요",
    "addNewCard": "새 신용카드 추가",
    "cardExpiration": "유효기간",
    "cardExpirationConfirm": "유효기간을 확인바랍니다.",
    "cardHolder": "카드 명의자",
    "cardHolderConfirm": "카드명의를 확인바랍니다.",
    "cardHolderFormatError": "이름은 반각 로마자로 입력해 주시기 바랍니다.",
    "cardHolderHelpOTA": "카드에 명시된 이름을 입력해 주십시오.",
    "cardHolderHelp": "카드에 명시된 이름을 입력해 주십시오.",
    "cardNumber": "카드 번호",
    "cardNumberConfirm": "카드번호를 확인바랍니다.",
    "cardNumberHelp": "카드번호를 입력해 주십시오.",
    "cardRequired": "카드 정보는 개런티 용도로 사용되며 취소료 발생 시 {0}에 따라 청구됩니다.",
    "cardSecurityCode": "시큐리티 코드(CVV)",
    "cardSecurityCodeConfirm": "시큐리티 코드(CVV)를 확인해 주세요.",
    "cardSecurityCodeHelp": "CVV",
    "ccvExampleOTA": "카드 뒷면(비자, 마스터카드, JCB, 다이너스 클럽) 또는 카드 앞면(아메리칸 익스프레스)에 있는 3자리 또는 4자리 숫자",
    "ccvExample": "카드 뒷면(Visa, Mastercard, JCB) 또는 앞면(American Express)에 인쇄된 3자리 또는 4자리 숫자입니다. 인터넷 쇼핑 시 고객이 합법적인 카드 소지자인지 확인하고 부정 행위를 방지하는 데 사용됩니다.",
    "creditCard": "신용카드",
    "expiredError": "체크아웃 날짜까지 유효한 신용카드를 등록해 주십시오",
    "month": "월",
    "payInAdvanceOTA": "{0}에 의한 신용카드 사전결제입니다.",
    "payInAdvance": "{0}에 의한 신용카드 사전결제입니다.",
    "payInAdvanceVoucher": "{0}에 의한 신용카드 사전결제입니다.",
    "payByCreditCard": "예약 완료 시, 여행대리점 ({0})를 통해 정산됩니다.",
    "payByCreditCardConsent": "고객님의 개인 정보는 여행대리점 tripla(주)와 공유됩니다. 이 예약을 진행함으로써 {0}, {1} 및 {2}에 동의합니다.",
    "tappayNote": "해당 시스템은 TapPay 2048-bit SLL 트랜잭션 시스템을 사용하며, 고객의 카드 정보는 저장되지 않습니다. 또한 TapPay는 PCI DSS 서비스 제공자 레벨 1의 인증을 통해 신용카드 정보를 보호합니다.",
    "year": "년",
    "cardBrand": "카드종류",
    "cardBrandMismatch": "입력하신 카드 번호는 선택 카드 브랜드에 해당되지 않습니다.",
    "paymentOverMillionError": "100만엔을 넘는 온라인 결제는 VISA, Master 카드로는 이용하실 수 없습니다. 다른 카드 브랜드를 이용하거나 다른 결제 타입을 선택하십시오.",
    "badCards": "시스템 변경으로 인해 다음 카드는 신규 예약에 이용할 수 없습니다. 다른 카드 정보를 다시 입력해 주십시오.",
    "gmoBadCards": "시스템 변경으로 인해 이전 신용카드를 저장하거나 선택하는 옵션을 일시적으로 비활성화하고 있습니다. 신용카드 결제의 경우 매번 카드정보를 입력해 주시기 바랍니다. 불편을 끼쳐드려 죄송합니다만, 아무쪼록 양해 부탁드립니다.",
    "pontaVisaCheckbox": "Route Inn Hotels PontaVISA 카드를 이용하시는 경우, 카드 브랜드로 [Route Inn Hotels PontaVISA 카드]를 선택해 주십시오. [VISA]를 선택하시면 보너스 포인트가 지급되지 않습니다.\n",
    "creditCardInvalid": "무효",
    "creditCardInvaildHoverText": "시스템 변경으로 인해 다음 카드는 신규 예약에 이용할 수 없습니다. 다른 카드 정보를 다시 입력해 주십시오.",
    "rrn": "생년월일 (YYMMDD)",
    "firstTwoDigitOfPin": "비밀번호 앞 두 자리 숫자\r\n",
    "payDirectlyOnsitePayment": "현장에서 호텔에 직접결제",
    "payByLocalCurrency": "현장에서 호텔에 JCB/AMEX/Diners 카드를 통해 현지통화로 결제",
    "exploreLocalCurrency": "또는 현지통화로 다른 결제 방법 살펴보기",
    "exploreOther": "또는 다른 결제방법 살펴보기"
  },
  "gmoPaypalOptions": {
    "null": "카드 브랜드를 선택하십시오.",
    "visa": "VISA",
    "ponta_visa": "VISA (Route Inn Hotels PontaVISA card)",
    "master": "Mastercard",
    "mastercard": "Mastercard",
    "amex": "American Express",
    "jcb": "JCB",
    "diners": "Diners Club"
  },
  "downloadPasswordModal": {
    "label": "암호화를 위해 비밀번호를 입력해 주시기 바랍니다. 다운로드 하신 zip파일은 이 비밀번호를 입력하시면 압축을 해제하실 수 있습니다."
  },
  "extraItems": {
    "error": {
      "extraNumber": "{name}의 개수를 변경하십시오. 예약 가능한 개수는 {min}〜{max}입니다."
    }
  },
  "extras": {
    "dateSelectTitle": "옵션 추가 날짜 선택",
    "dateSelectBtn": "수량 선택으로",
    "goToGuestDetails": "고객 정보 입력",
    "stock": "재고",
    "selectableQuantity": "선택 가능 수량",
    "remainingExtras": "남은 옵션 수"
  },
  "facilitySuggestion": {
    "distanceFrom": "거리 :이 시설로부터 {n}km",
    "from": "From",
    "header": "다른 가능한 인근 시설",
    "soldOut": "공실이 없습니다. 원하는 조건을 변경하고 다시 검색하십시오.",
    "soldOutDesc": "죄송합니다.지정 조건으로 예약 가능한 객실은 없습니다.",
    "visitHotelWebsite": "호텔 웹사이트 방문"
  },
  "guestDetail": {
    "afterUpdate": "변경 후",
    "bookingForSomeone": "예약자와 투숙자가 다를 경우 클릭해서 투숙객 정보를 입력해 주세요.",
    "brrDiscount": "최적의 객실 요금 할인",
    "confirmUpdates": "예약 변경 확정",
    "confirmPayByCard": "신용카드 결제 확인",
    "coupon": "쿠폰",
    "couponApplied": "적용완료",
    "couponAppliedNew": "적용된 쿠폰:",
    "creansmaerdCouponApplied": "쿠폰코드 {code} 이/가 적용 되었습니다.",
    "couponCode": "쿠폰 코드",
    "couponDiscount": "쿠폰 할인",
    "couponExpired": "이 쿠폰 코드는 유효하지 않습니다.",
    "couponInvalid": "입력된 쿠폰 코드가 올바르지 않습니다.",
    "couponLimitReached": "해당 쿠폰 {code} 은/는 이용 한도에 도달했기 때문에 이용하실 수 없습니다.",
    "couponMaxStays": "쿠폰 할인은 숙박 첫날 기준 최대 {n}박까지 적용할 수 있습니다. 숙박 첫날 기준 {n}박 이후에는 일반 요금이 적용됩니다.",
    "couponTargetPlans": "쿠폰 코드 적용 대상 플랜",
    "couponNotUse": "쿠폰을 사용하지 않습니다",
    "couponValidUntil": "{date}까지 유효",
    "couponUsageQuantityLimit": "쿠폰 코드는 한 번에 하나만 적용할 수 있습니다. 새 코드를 적용하려면 기존에 추가된 코드를 삭제하세요.",
    "creditCardWarning": "결제한 요금 전액을 환불하며, 예약 변경 후의 가격으로 청구됩니다.",
    "availableCoupons": "사용 가능한 쿠폰",
    "viewMoreCoupons": "쿠폰 더보기",
    "removeCoupon": "삭제",
    "day": "{n}일",
    "email": "이메일 ({email}을 수신설정에서 허용해 주시기 바랍니다.)",
    "emailAgain": "이메일(확인용)",
    "emailAgainConfirm": "이메일 주소가 일치하지 않습니다.",
    "emailAgainHelp": "이메일 주소를 다시 입력해 주세요.",
    "emailConfirm": "올바른 이메일주소를 입력하십시오",
    "emailHelp": "이메일주소를 입력하세요.",
    "extras": "옵션 판매",
    "extraLeft": "나머지 {count} {unit}",
    "extraOutOfStock": "품절",
    "fullName": "이름",
    "fullNameError": "이름을 영어로 입력해 주시기 바랍니다.",
    "goPreviousScreenConfirm": "이전 화면으로 돌아가시겠습니까?",
    "goPreviousScreenDescription": "현재 페이지에 입력한 모든 정보는 저장되지 않으니 유의하시기 바랍니다.",
    "goPreviousScreenContinue": "계속",
    "guestDetails": "고객정보",
    "guestName": "고객명",
    "guestNameError": "이름을 영어로 입력해 주시기 바랍니다.",
    "guestNameHelp": "전체 이름을 입력하십시오.",
    "guestNameKanji": "투숙자명(한자)",
    "guestStayName": "투숙자명",
    "mainGuest": "대표 연락처",
    "notes": "알림",
    "originalPaymentAmount": "기존 결제 금액",
    "promotionDiscount": "할인",
    "phone": "전화번호",
    "phoneConfirm": "번호와 +만 사용하여 정확한 전화번호를 입력하시기 바랍니다.",
    "phoneHelp": "전화번호를 입력하십시오",
    "priceBecomeCheaper": "현재 선택중인 플랜에 적용되는 프로모션이 호텔에 의해 가격이 인하되었습니다. 현재 가격을 확인하시고 예약을 진행해 주시기 바랍니다.",
    "priceBecomeExpensive": "현재 선택중인 플랜에 적용되는 프로모션 내용이 호텔에 의해 변경되어, 가격이 인상되었습니다. 현재 가격을 확인하시고 예약을 진행해 주시기 바랍니다.",
    "priceDiff": "가격 차액",
    "signupTerms": "본 예약시스템은 tripla(주)에 의해 제공되며, 고객의 개인정보는 tripla(주)와 공유됩니다. 이를 인지하고 본 멤버십등록을 진행하며 tripla {0} , {1} 및 , {2} 에 동의합니다.",
    "terms": "고객님의 개인 정보는 예약 시스템을 제공하고 있는 tripla(주)와 공유되는 것을 인지하고, 예약 완료와 함께 tripla {0}, {1} 및 {2}에 동의합니다.",
    "totalAmount": "합계 금액 ",
    "totalAmountWithTax": "합계 금액 (세금포함)",
    "totalWithoutTax": "합계 금액 (세금 미포함)",
    "updateUserInformation": "위 내용으로 멤버십 정보를 업데이트합니다 ( 이메일 제외)",
    "cheaperAccommodationTax": "포인트를 사용하여 예약 시 숙박세가 저렴해졌습니다. 최신 요금을 확인한 후 예약을 진행하십시오.",
    "roomGuestName": "객실 {0} 게스트 이름",
    "confirmation": {
      "title": "{0} 로 표시된 부분은 필수항목입니다.",
      "description": "예약 후 숙박자명의 변경이 불가합니다. 반드시 숙박자 본인 이름을 입력해 주세요."
    }
  },
  "inventoryCalendar": {
    "availableIconDescription": "{icon}: 공실 있음",
    "unavailableIconDescription": "{icon}: 공실 없음",
    "uncertainIconDescription": "{icon}: 잔여 객실 있음"
  },
  "lineLogin": {
    "buttonText": "LINE으로 로그인"
  },
  "meta": {
    "facilities": "브랜드 시설",
    "sign_in": "로그인",
    "organization_sign_in": "법인 로그인",
    "sign_up": "회원가입",
    "search_results": "플랜 검색",
    "guest_detail": "고객 정보 입력",
    "payment_method": "결제 정보 입력",
    "lotteryCompleted": "추첨 응모 완료",
    "completed": "예약 완료",
    "reservationIncomplete": "미완료 예약",
    "my_account": "마이 페이지",
    "otaHome": "AI 검색을 통한 스마트한 호텔 선택",
    "otaHomeDescription": "tripla AI 검색 기능을 통해 선정된 2000개 이상의 숙소를 확인해 보세요. 전통 료칸부터 고급 리조트까지, 완벽한 숙소를 손쉽게 찾으실 수 있습니다.",
    "otaSearchHotels": "검색 결과",
    "otaSearchResults": "플랜 검색",
    "otaGuestDetail": "고객 정보 입력",
    "otaPayment": "결제 정보 입력",
    "otaConfirmation": "예약 완료",
    "otaBookingConfirmation": "예약 확인"
  },
  "membership": {
    "promotionRate": "프로모션 내용",
    "regularRate": "일반요금",
    "signUpFor": "멤버십요금"
  },
  "membershipForm": {
    "address": "주소",
    "alreadyHaveAccount": "이미 계정을 가지고 있음",
    "back": "돌아가기",
    "buildingName": "건물명・객실번호",
    "changePassword": "비밀번호 변경",
    "changePasswordResult": "비밀번호 재설정",
    "changePasswordResult02": "✓ 고객님의 비밀번호 재설정이 완료되었습니다.",
    "changePasswordResult03": "새로운 비밀번호로 여기에서 다시 로그인 해주시기 바랍니다.",
    "changePasswordSecondary": "새로운 비밀번호를 입력해 주십시오",
    "confirmDeletedResult": "탈퇴 처리가 완료되었습니다.",
    "confirmNewPassword": "비밀번호 확인",
    "confirmPassword": "비밀번호 확인",
    "confirmRegisterFail": "오류가 발생하였습니다.",
    "confirmRegisterFail02": "다시 등록해 주시기 바랍니다.",
    "confirmRegisterResult": "이메일 주소 인증",
    "confirmRegisterResult02": "✓ 성공적으로 등록되었습니다.",
    "confirmRegisterResult03": "즐거운 시간 되시길 바랍니다!",
    "createAccount2": "회원등록",
    "createAccount3": "회원등록",
    "createAccount4": "새 계정을 만드세요",
    "dob": "생년월일",
    "email": "이메일 주소",
    "emailError": "이메일 주소를 확인하시기 바랍니다",
    "emailPromotionCheck": "호텔에서 프로모션 이메일 받기",
    "english_error": "영어로 입력해 주십시오",
    "locale_error": {
      "ja": "영어로 입력해 주십시오",
      "zh_Hant": "영어로 입력해 주십시오",
      "ko": "영어로 입력해 주십시오"
    },
    "enterDetail": "로그인 정보를 입력해주시기 바랍니다.",
    "first_name": "이름(영문)",
    "firstNameLocales": {
      "ja": "이름(영문)",
      "zh_Hant": "이름(영문)",
      "ko": "이름(영문)"
    },
    "first_name_kanji": "",
    "first_name_kanji_placeholder": "",
    "first_name_placeholder": "Gildong",
    "firstNamePlaceHolderLocales": {
      "ja": "Gildong",
      "zh_Hant": "Gildong",
      "ko": "길동"
    },
    "firstName": "이름",
    "firstNameError": "이름을 입력해 주시기 바랍니다",
    "firstNameKana": "",
    "forgotPassword": "비밀번호가 기억나지 않습니다",
    "forgotPassword02": "비밀번호 재설정",
    "forgotPasswordError": "입력하신 이메일 주소는 등록되어 있지 않습니다.",
    "forgotPasswordResult": "비밀번호 재설정",
    "forgotPasswordResult02": "✓ 이메일이 전송되기까지 시간이 소요될 수 있습니다. 10분 정도가 경과해도 메일이 전송되지 않는 경우에는 아래의 재전송 버튼을 클릭하시면 재설정을 위한 이메일을 다시 전송합니다.",
    "forgotPasswordResult02New": "✓ {email}에 대한 비밀번호 재설정 요청이 접수되었습니다.",
    "forgotPasswordResultSecondary": "이메일이 전송되기까지 시간이 소요될 수 있습니다. 10분 정도가 경과해도 메일이 전송되지 않는 경우에는 아래의 재전송 버튼을 클릭하시면 재설정을 위한 이메일을 다시 전송합니다.",
    "forgotPasswordResultSecondaryNew": "입력한 이메일로 가입된 계정이 있을 경우, 비밀번호 재설정 안내 이메일이 전송됩니다. 메세지가 도착하는데에는 최대 10분 정도 소요될 수 있으며, 비밀번호 재설정을 다시 시도하기 전에 스팸 또는 정크폴더를 확인해 주세요.",
    "forgotPasswordResultSecondary02New": "10분 이내에 이메일을 받지 못하면 계정이 없을 수 있습니다. 다른 이메일을 시도하거나 {0}.",
    "forgotPasswordSecondary": "이메일 주소를 입력해주시기 바랍니다. 이메일 계정으로 비밀번호를 재설정하실 수 있는 링크를 보내드립니다.",
    "gender": "성별",
    "generalError": "죄송합니다. 처리중에 에러가 발생하였습니다. 잠시 후에 다시 시도해 주시기 바랍니다.",
    "guest_first_name": "이름(영문)",
    "guest_first_name_kanji": "",
    "guest_last_name": "성(영문)",
    "guest_last_name_kanji": "",
    "header": "회원등록",
    "hiragana_error": "",
    "kanji_katakana_error": "",
    "kanji_error": "",
    "last_name": "성(영문)",
    "lastNameLocales": {
      "ja": "성(영문)",
      "zh_Hant": "성(영문)",
      "ko": "성"
    },
    "last_name_kanji": "",
    "last_name_kanji_placeholder": "",
    "last_name_placeholder": "Hong",
    "lastNamePlaceHolderLocales": {
      "ja": "Hong",
      "zh_Hant": "Hong",
      "ko": "홍"
    },
    "lastName": "성",
    "lastNameError": "성을 입력해 주시기 바랍니다",
    "lastNameKana": "",
    "membershipId": "멤버십 번호",
    "membershipProgram": "멤버십 프로그램",
    "name": "고객명",
    "nameJaPlaceholder": "",
    "nameJaValidation": "",
    "nameInvalidCharMessage": "입력한 문자에는 시스템에서 지원되지 않는 문자가 포함되어 있습니다.",
    "nameKanaJaPlaceholder": "",
    "namePlaceholder": {
      "en": "",
      "ja": "히라가나 또는 영어를 입력하세요.",
      "zh_Hant": "한자 또는 영어를 입력하세요.",
      "zh_Hans": "한자 또는 영어를 입력하세요.",
      "ko": "한글 또는 영어를 입력하세요."
    },
    "nameValidation": {
      "ja": "히라가나 또는 영어를 입력하세요.",
      "zh_Hant": "한자 또는 영어를 입력하세요.",
      "zh_Hans": "한자 또는 영어를 입력하세요.",
      "ko": "한글 또는 영어를 입력하세요."
    },
    "nationality": "국적",
    "newPassword": "새 비밀번호 (알파벳 소문자 및 대문자 ・ 숫자를 최소 한 글자씩 포함하여 8자 이상)",
    "noAccount": "아직 멤버십등록을 하지 않으셨나요?",
    "occupation": "직업",
    "or": "또는",
    "orContinueBelow": "또는 다음 정보를 입력",
    "password": "비밀번호",
    "passwordConfirmationError": "비밀번호가 일치하지 않습니다.",
    "passwordError": "비밀번호를 확인하시기 바랍니다.",
    "passwordSignUp": "비밀번호 (알파벳 소문자 및 대문자 ・ 숫자를 최소 한 글자씩 포함하여 8자 이상)",
    "passwordNote": "알파벳 소문자 및 대문자 ・ 숫자를 최소 한 글자씩 포함하여 8자 이상",
    "pleaseSelect": "멤버십 프로그램을 선택",
    "postCode": "우편번호",
    "postCodeError": "알파벳과 숫자만 허용됩니다.",
    "prefecture": "시도군구",
    "notApplicable": "해당 없음",
    "privacyPolicy": "개인정보 보호정책",
    "promotionsCheckbox": "이메일로 이벤트 및 프로모션 관련 정보를 수신하겠습니다.",
    "resend": "재전송",
    "resendConfirmationEmail": "회원등록 확인메일을 받지 못하셨나요?",
    "resendConfirmationEmailError": "해당 이메일주소는 등록되어 있지 않습니다.",
    "resendConfirmationEmailTitle": "회원등록 확인메일 재전송",
    "receiptName": "영수증 이름",
    "receiptUnicode": "",
    "send": "전송",
    "setPassword": "변경",
    "setup": "회원등록",
    "setupResultSecondary01": "여기에서 체크인 / 아웃 날짜를 지정하시면 티켓(바우처)을 사용하여 예약으로서 확정할 수 있습니다.",
    "setupResultSecondary02": "\n확인을 위해 등록하신 이메일 주소로 이메일을 발송하였습니다. 멤버십등록을 완료하기 위해 보내드린 이메일의 URL을 클릭하여 등록을 완료해 주시기 바랍니다. ",
    "signIn": "로그인",
    "signInHere": "로그인",
    "signInTo": "{0}에 로그인",
    "signUp": "회원등록",
    "signUpResult": "이메일 주소를 승인해주세요.",
    "signUpResult02": "성공적으로 등록되었습니다.",
    "signUpResult02Secondary": "%{hotel_name}에 멤버로 등록해주셔서 감사합니다.",
    "signUpResult02Secondary02": "입력하신 이메일 주소로 확인 이메일이 발송되었습니다.",
    "signUpResultSecondary": "{n}에 등록해주셔서 감사합니다.",
    "signUpResultSecondary02": "계정 사용을 위해 등록하신 이메일 주소를 확인해 주시기 바랍니다.",
    "signUpResultSecondary03": "내용 확인 후 URL을 클릭해 주시기 바랍니다.",
    "signUpResultSecondary04": "멤버십 등록을 위해 이메일 주소를 승인해 주시기 바랍니다.",
    "signUpSecondary": "멤버십에 등록하세요! 1분도 걸리지 않습니다!",
    "term": "등록함으로 {0}과 {1}에 동의합니다. ",
    "termError": "이용약관에 동의해 주시기 바랍니다. ",
    "termOfUse": "이용약관",
    "wifi": "객실 내 무료 와이파이",
    "yetReceived": "이메일을 받지 못하셨나요? {0}",
    "resendEmail": "이메일 재전송",
    "pleaseResetPassword": "이메일 계정 등록이 확인되었으므로 확인 이메일을 다시 보낼 필요가 없습니다.\n비밀번호를 알고 계시다면 {0}\n비밀번호를 모르는 경우 {1} 옵션을 사용하십시오. ",
    "pleaseResetPasswordLink1": "직접 로그인 해주세요",
    "pleaseResetPasswordLink2": "비밀번호 재설정",
    "receiptNameFormatError": "",
    "receiptUnicodeFormatError": "",
    "receiptNameRequiredError": "",
    "receiptUnicodeRequiredError": ""
  },
  "membersihp": {
    "target": "대상 멤버십"
  },
  "multipleCurrency": {
    "JPY": "일본 엔",
    "ARS": "아르헨티나 페소",
    "AUD": "오스트레일리아 달러",
    "BRL": "브라질 레알",
    "CAD": "캐나다 달러",
    "CNY": "중국 위안",
    "CZK": "체코 코루나",
    "DKK": "덴마크 크로네",
    "EUR": "유로",
    "HKD": "홍콩 달러",
    "INR": "인도 루피",
    "IDR": "인도네시아 루피아",
    "ILS": "이스라엘 신 셰켈",
    "MOP": "마카오 파타카",
    "MYR": "말레이시아 링기트",
    "MXN": "멕시코 페소",
    "TWD": "신 타이완 달러",
    "NZD": "뉴질랜드 달러",
    "NOK": "노르웨이 크로네",
    "PHP": "필리핀 페소",
    "GBP": "영국 파운드",
    "QAR": "카타르 리얄",
    "RUB": "러시아 루블",
    "SAR": "사우디아라비아 리알",
    "SGD": "싱가포르 달러",
    "ZAR": "남아프리카공화국 랜드",
    "KRW": "대한민국 원",
    "LKR": "스리랑카 루피",
    "SEK": "스웨덴 크로나",
    "CHF": "스위스 프랑",
    "THB": "태국 바트",
    "AED": "아랍에미리트연합국 디르함",
    "USD": "미국 달러",
    "VND": "베트남 동",
    "modalTitle": "화폐 선택",
    "modalDescription": "화폐를 선택해 주세요. 일본 엔화가 아닌 다른 화폐를 선택하시면 결제수단이 신용카드로 제한됩니다.",
    "suggestedCurrencies": "자주 사용되는 화폐 ",
    "allCurrencies": "모든 화폐 ",
    "creditCardWarning": "결제 통화가 변경되었습니다. 현지 통화 결제는 해당 서비스를 지원하는 신용카드로 결제할 때만 가능합니다. Visa/MasterCard를 선택하면 현지 통화로 결제할 수 있어 예상치 못한 환전 수수료를 피할 수 있습니다.",
    "exchangeRateUpdatedError": "환율이 업데이트되었으니 최신 가격을 확인하고 예약하세요.",
    "currency": "화폐"
  },
  "myAccount": {
    "availablePoints": "사용 가능{0}",
    "availablePointsDesc": "현재 사용 가능한{0}의 합계입니다.",
    "totalRankingPoints": "랭크업 대상의 {pointName}은 {amount} {pointName}을 획득했습니다.",
    "changeLoginEmail": "이메일 주소 변경",
    "changeLoginEmailDescription": "이메일주소를 변경하시면 새로운 주소로 확인 이메일이 전송되고 본문의 URL을 클릭하시면 변경이 완료됩니다. 이메일 수신에 문제가 있는 경우 다시 시도해 주시기 바랍니다.",
    "changeLoginEmailPendingText": "새로운 이메일 주소가 업데이트 되어 확인 중에 있습니다.",
    "changePassword": "비밀번호 재설정",
    "confirmLoginEmail": "새로운 이메일 주소(확인 중)",
    "edit": "편집",
    "emailLabel": "이메일",
    "loginEmail": "이메일 로그인",
    "loginInfo": "로그인 정보",
    "notRegistered": "등록된 카드가 없습니다.",
    "pageTitle": "계정내용",
    "password": "비밀번호",
    "passwordLabel": "비밀번호",
    "paymentInfo": "결제 정보",
    "paymentOptions": "결제 방법",
    "paymentOptionsDesc": "등록된 신용카드 정보는 다음과 같습니다. 신용카드와 관련된 모든 정보는 엄격하게 기밀로 관리됩니다.",
    "pendingEmailText": "{n} 이메일 주소 확인중",
    "personalInfo": "개인 정보",
    "promotions": "쿠폰",
    "point": "포인트",
    "removeAccount": "회원탈퇴",
    "removeAccountConfirmationText": "탈퇴하시면 이후 복구가 불가합니다. 탈퇴하시겠습니까?",
    "removeAccountReason01": "이용하지 않음",
    "removeAccountReason02": "높은 객실요금",
    "removeAccountReason03": "멤버십혜택이 매력적이지 않음",
    "removeAccountReasonInput": "이유를 기재해 주세요. ",
    "removeAccountReasonOthers": "기타 (다른 이유를 기재해 주세요.)",
    "removeAccountSecondaryText": "탈퇴하시면 기존의 회원 특전이 사라집니다.",
    "removeAccountSecondaryText02": "탈퇴 시 복구가 불가합니다.",
    "removeCreditCard": "카드 삭제",
    "removeCreditCardConfirm": "등록되어 있는 카드가 삭제됩니다. 진행하시겠습니까?",
    "resendEmail": "확인 이메일 재전송",
    "resendEmailSuccess": "확인 이메일이 재전송 되었습니다.",
    "yourCreditCards": "등록된 카드",
    "membershipId": "멤버십 ID",
    "myQRCode": "내 QR 코드",
    "promotion": "프로모션 이메일 설정",
    "historyStatus": {
      "expired": "만료된 포인트",
      "future": "획득 예정 포인트",
      "spent": "사용한 포인트",
      "gained": "획득 포인트",
      "refunded": "환불 포인트"
    },
    "value": "가치",
    "badCards": "시스템 변경으로 인해 해당 카드는 신규 예약에 이용할 수 없습니다.",
    "unlockRank": "{date} 이전에 {points}{pointName}을 모아 {rank}랭크가 됩니다.",
    "unlockRankNoDate": "{points}{pointName}을 모아{rank}랭크가 됩니다.",
    "earnMorePointsToKeepRank": "현재 {rank} 랭크를 유지하려면 {date} 이전에 {points}{pointName}을 더 획득하세요.",
    "achievedHighest": "축하합니다! 최고 등급을 달성하셨습니다.",
    "achievedHighestOnDate": "축하합니다! 조건을 충족하여 {date}에 최고 등급에 도달합니다."
  },
  "myProfile": {
    "cancel": "예약 취소",
    "cancelled": "취소됨",
    "change": "변경",
    "checkIn": "체크인",
    "checkOut": "체크아웃",
    "confirmationNo": "예약 번호",
    "multiRoom": "복수 객실",
    "myReservations": "예약",
    "myPage": "마이페이지",
    "noResultButtonText": "가능 객실 검색",
    "noResultText": "현재 예약이 없습니다.",
    "pageTitle": "계정 정보",
    "viewDetails": "상세보기",
    "viewFlightDetails": "예약상세 (항공권 포함)",
    "myVoucher": {
      "amount": "금액",
      "askConsume": "쿠폰을 사용하시겠습니까?",
      "askConsumeMessage": "이 쿠폰은 한 번만 사용하실 수 있습니다.",
      "cancel": "취소",
      "consume": "사용",
      "consumeVoucher": "쿠폰 사용",
      "excludeDuring": "다음 기간을 제외",
      "makeReservation": "예약하기",
      "targetStayingPeriod": "대상 숙박기간",
      "validThrough": "적용대상 판매기간",
      "name": "프로모션명",
      "consumptionTime": "사용 시간",
      "reservationNumber": "예약번호",
      "status": {
        "status": "상태",
        "available": "사용 가능",
        "pending": "보류 중",
        "expired": "만료됨",
        "consumed": "사용됨"
      },
      "selectHotel": "호텔 선택"
    },
    "lottery": {
      "status": {
        "registered": "진행 중",
        "not_winner": "비당첨",
        "winner_reservation_confirmed": "당첨 (예약됨)",
        "winner_reservation_cancelled": "예약 취소됨",
        "registration_cancelled": "응모 취소됨",
        "winner_reservation_pending_declined": "응모 취소됨",
        "winner_reservation_pending": "당첨 (비예약)"
      },
      "text": "추첨"
    },
    "processBooking": "예약 하기",
    "cancelEntry": "응모 취소",
    "lotteryEntryNo": "추첨 응모 번호"
  },
  "mcp": {
    "chooseCurrency": "원하는 통화 선택",
    "recommendLocalCurrency": "VISA 또는 MASTER 카드를 선택하시면 현지통화로 결제하실 수 있으며 추가 수수료를 피하실 수 있습니다. 현재 다른 신용카드는 다중통화 거래를 지원하고 있지 않습니다."
  },
  "organization": {
    "enterDetail": "ID와 비밀번호를 입력해 주십시오. ",
    "signIn": "법인 로그인"
  },
  "ota": {
    "changeLoginEmailPendingText": "새로운 이메일주소가 확인중입니다. 업데이트시에 보내드리는 내용에 기재된 URL을 클릭하시면 반영됩니다.",
    "name": {
      "agoda": "Agoda",
      "booking_com": "Booking com",
      "expedia": "Expedia",
      "jalan": "Jalan",
      "rakuten_travel": "Rakuten Travel",
      "rakutenTravel": "Rakuten Travel"
    },
    "priceNotAvailable": "이용 불가",
    "loadingTitle0": "완벽한 숙박 장소 찾기",
    "loadingDescription0": "AI를 활용한 맞춤형 호텔을 추천해 드립니다. 꿈꾸던 숙소가 로딩 중입니다...",
    "loadingTitle1": "일본 문화 체험",
    "loadingDescription1": "전통 다도를 즐기며 일본의 풍부한 문화에 흠뻑 빠져보세요. 말차를 준비하는 정교한 과정을 체험하며 그 고요함을 느껴보세요.",
    "loadingTitle2": "아는 사람만 아는 숨은 명소",
    "loadingDescription2": "잘 알려지지 않은 동네를 탐험해 보세요. 그 지역의 숨겨진 보석 같은 매력을 발견하고, 도시와 어우러지는 매력으로 빠져들어보세요.",
    "loadingTitle3": "전통과의 조우",
    "loadingDescription3": "게이샤 거리의 마법에 빠져보세요. 전통춤을 감상하고 다도체험을 통해 진정한 문화의 매력을 느껴보세요.",
    "loadingTitle4": "미식 체험",
    "loadingDescription4": "다양한 길거리 음식, 시장 음식을 맛보며 일본 미식의 세계로 빠져보세요. 신선한 스시부터 맛있는 타코야키까지, 당신의 미각을 만족시키세요. ",
    "loadingTitle5": "자연 속 평온함",
    "loadingDescription5": "혼잡한 도시에서 벗어나 매혹적인 대나무 숲을 산책해 보세요. 유서 깊은 도시의 고요함과 시대를 초월한 아름다움을 경험하세요.",
    "loadingTitle6": "벚꽃의 향연",
    "loadingDescription6": "분홍빛으로 물든 벚꽃 절경의 놀라움을 경험해 보세요. 현지인들과 함께 벚꽃놀이를 즐기며 마법과도 같은 시간을 느껴 보세요.  ",
    "fromBwToOtaPaymentError": "문제가 발생했습니다.「다음」을 누르거나 다른 결제 방식을 선택하신 후, 다시 시도해 주십시오.",
    "fromBwToOtaRestoreDataError": "문제가 발생했습니다. 정보를 다시 입력한 후「다음」버튼을 다시 눌러주세요.",
    "searchHotels": "호텔 검색",
    "aiRecommendations": "AI 추천",
    "recommendationsForU": "당신을 위한 추천",
    "interestedIn": "당신을 위한 추천 상품",
    "home": "홈페이지",
    "showingCountAvailable": "{count}건 중, {total}건 표시중 ",
    "standardTravelAgency": "여행사영업 표준 일반약관",
    "termsOfUse": "멤버십관련 정책",
    "privacyPolicy": "개인보호정책",
    "companyProfile": "회사 정보",
    "bookingSupport": "예약 지원",
    "search": "호텔 검색",
    "checkAvailability": "예약 가능 여부 확인하기",
    "avgPricePerNight": "1박당 기준 가격",
    "adults": "성인 {n}명",
    "GDPR": "GDPR 개인보호정책",
    "homepage": {
      "recommend1": "주말 숙박을 위해 도쿄의 저렴한 호텔을 찾아주세요.",
      "recommend2": "3인용 오키나와 리조트호텔을 찾는 데 도움을 주세요.",
      "recommend3": "아내의 생일을 맞아 교토에 있는 료칸을 찾고 싶어요.",
      "recommend4": "다음 달 고베로 출장을 갑니다. 혼자 숙박하기에 좋은 호텔이 있나요?",
      "placeholder": "예: 신주쿠 인근 호텔에서 2인 3박 예약 희망"
    },
    "bookingConfirmation": "예약 확인",
    "confirmToOtaTitle": "예약 확인 절차",
    "confirmToOtaDescription": "여행대리점 사이트({0})로 이동 중입니다. 다음 페이지에서 사전 결제를 완료하면 예약이 완료됩니다.",
    "confirmToOtaDescriptionInGuestForm": "여행대리점 사이트({0})로 이동 중입니다. 다음 페이지에서 사전 결제를 완료하면 예약이 완료됩니다.",
    "confirmToOtaDescriptionInGuestFormOnsiteCC": "여행대리점 사이트({0})로 이동 중입니다. 다음 페이지에서 신용카드를 등록하면 예약이 완료됩니다.\n\n",
    "aiSearchNoResult": "현재 AI가 학습 중이며 고객님께서 입력하신 키워드로 추천 정보를 찾지 못했습니다. 장소, 인원수, 숙소 등 보다 자세한 정보를 제공해 주세요. 고객님께 다음과 같은 숙소를 추천해 드립니다. "
  },
  "otaRoomsResult": {
    "noRoomsAvailable": "고객님의 요구 사항에 맞는 객실이 없습니다. ",
    "noRoomsAvailableDesc": "다른 날짜를 선택하시거나, 숙박 인원을 변경하여 다시 검색해 주십시오.",
    "location": "다음 위치",
    "showAllPhotos": "모든 이미지 표시"
  },
  "otaRoomsMap": {
    "title": "지도",
    "seeMap": "지도에서 보기"
  },
  "paymentMethod": {
    "paymentKRWCreditCardFooterTitle": "온라인 결제는 \n다음과 같이 처리됩니다 :",
    "paymentKRWCreditCardFooterLine1": "트리플라 주식회사 (영업소)",
    "paymentKRWCreditCardFooterLine1CEO": "대표자: Kazuhisa Takahashi",
    "paymentKRWCreditCardFooterLine2": "사업자 등록 번호 : 538-84-00072",
    "paymentKRWCreditCardFooterLine3": "서울시 종로구 새문안로 76 콘코디언 빌딩 3층 (P. 03185)",
    "paymentKRWCreditCardFooterLine4": "support_ko{'@'}tripla.io",
    "paymentKRWCreditCardFooterLine4Link": "mailto:support_ko{'@'}tripla.io",
    "paypalCheckbox1": "반드시 예약하신 분의 신용카드를 사용해주시기 바랍니다. 카드 소유자의 이름이 예약 이름과 일치하지 않을 경우 사전 통보 없이 예약이 취소될 수 있습니다.",
    "paypalCheckbox2": "신용카드 발급 회사가 3D 보안을 지원하지 않는 경우에는 이용할 수 없습니다.",
    "paypalCheckbox3": "당사는 고객으로부터 수집한 아래의 개인정보 등을 카드 발급 회사가 실시하는 부정이용 검지, 방지를 위해 고객이 이용하고 있는 카드 발급 회사에 제공합니다. 이름, 전화번호, 메일 주소, 인터넷 이용 환경에 관한 정보 등.",
    "paypalCheckbox4": "고객이 이용하는 카드 발급 회사가 외국에 있는 경우, 해당 정보는 해당 발급 회사가 속한 국가로 이전될 수 있습니다.",
    "paypalCheckbox5": "고객이 미성년인 경우, 친권자 또는 후견인의 승낙을 얻은 후에 본 서비스를 이용할 수 있습니다.",
    "pre_paid": "신용카드 결제",
    "credit_card": "사전 결제",
    "payments": {
      "credit_card": "신용카드 결제"
    },
    "deferred_payment": "후불 결제",
    "on_site": "현지결제",
    "on_site_credit_card_required": "현지결제",
    "type": "지불유형",
    "twd_credit_card": "신용카드로 전액 결제",
    "twd_credit_card_deposit": "신용카드로 결제 보증",
    "bank_transfer": "은행 이체",
    "paymentPromotion": "결제 프로모션",
    "paymentPromotionTooltip": "프로모션은 예고 없이 변경 또는 종료될 수 있습니다.",
    "undefined": ""
  },
  "pointHistory": {
    "availableAt": "사용 가능 시간",
    "cancellation": "취소",
    "dateColumn": "날짜",
    "descriptionColumn": "내용",
    "failToLoadErrorMessage": "포인트 이력을 불러오는 데 실패했습니다.",
    "noData": "포인트 이력이 없습니다.",
    "pointExpirationColumn": "유효기간",
    "pointHistory": "포인트 이력",
    "pointsColumn": "포인트 금액",
    "pointUse": "포인트 사용",
    "stay": "투숙",
    "viewMore": "전체 보기",
    "exchangeDigico": "{point} 교환",
    "exchangeDigicoDesc": "디지코 포인트로 교환하고자 하는 {point} 금액을 입력해주십시오. Digico 포인트는 Amazon, Apple, Google 포인트를 포함한 다른 포인트 프로그램으로 포인트를 교환하는 데 사용할 수 있습니다. 이 절차는 되돌릴 수 없으며 Digico 포인트는 교환 후 180일 동안만 유효합니다. 이 서비스는 일본 국내에서만 액세스할 수 있습니다. 시설은 교환된 Digico 포인트에 대한 질문에 답변할 수 없습니다. Digico 포인트에 대한 자세한 내용은 {link}을 참조하십시오.",
    "exchangeDigicoLink": "여기",
    "exchangeDigicoLog": "디지코 포인트 교환",
    "exchangeAvaliablePoints": "이용 가능한 총 {point}",
    "exchangeBtn": "교환",
    "exchangeHistory": "{point} 교환",
    "exchangedAmount": "교환 금액",
    "exchangedDigicoCode": "코드",
    "exchangeConfirm": "이 프로세스는 되돌릴 수 없습니다. {point} 교환을 진행 하시겠습니까?",
    "exchangeConfirmBtn": "계속",
    "digicoPointUrl": "디지코 포인트 교환 URL:",
    "invalidAmount": "유효한 포인트 금액을 입력하십시오.",
    "totalBalance": "총 사용 가능 포인트",
    "currentPoints": "사용 가능한 포인트",
    "futurePoints": "획득 예정 포인트"
  },
  "pointSpending": {
    "balance": "사용가능 포인트",
    "errors": {
      "incorrectAmount": "포인트는 {n}포인트씩 사용하실 수 있습니다.",
      "moreThanPrice": "숙박금액 이상으로는 포인트를 사용하실 수 없습니다.",
      "upToBalance": "남아있는 포인트 이상으로는 설정하실 수 없습니다.",
      "pointUsageError": "총 결제 금액이 0인 경우 신용카드 결제를 진행하실 수 없습니다.\n온라인 결제를 활성화하려면 이전 단계에서 지출 포인트를 줄일 수 있습니다. "
    },
    "minimumAmount": "포인트는 {0}포인트 이상부터 사용하실 수 있습니다.",
    "points": "포인트",
    "saveForLater": "포인트를 사용하지 않음",
    "updateRule": "기존 예약에 사용된 포인트는 사용하실 수 없습니다. 기존 예약에 사용된 포인트는 예약이 업데이트 된 후에 환불됩니다.",
    "useAll": "전체 포인트 사용",
    "useAmount": "사용 포인트 설정",
    "title": "포인트"
  },
  "points": {
    "naming": {
      "default": "포인트",
      "history": "{point} 이력",
      "available": "사용 가능 {point}",
      "future": "획득 예정 {point}",
      "expired": "만료됨 {point}",
      "gained": "취득 {point}",
      "spent": "사용한 {point}",
      "save": "{point}를 나중에 사용할 수 있도록 저장",
      "redeem": "{point}잔액을 전액 교환",
      "redeem2": "최대 {point} 사용",
      "refunded": "환불 {point}",
      "miniSpending": "최소 사용{point}수는 {number} 이상이어야 합니다.",
      "useIncrements": "{point}는 {number} 단위로 사용할 수 있습니다.",
      "discount": "{point} 할인",
      "earn": "해당 객실을 예약하면 {number}{point}을 획득할 수 있습니다.",
      "checkOut": "{point}는 체크아웃 날짜 3일 후에 추가됩니다.",
      "recalculateOnCheckoutDate": "표시된 {point} 총액은 예상금액입니다. 정확한 금액은 체크아웃 날에 다시 계산됩니다.",
      "usePoint": "{point}이용",
      "empty": "현재 {point}이력이 없습니다.",
      "total": "총 잔액",
      "availableBalance": "사용가능 {point}",
      "redeemRemark": "사용 {point} 설정",
      "cannotUse": "기존 예약에 사용된 {point}는 사용하실 수 없습니다. 기존 예약에 사용된 {point}는 예약이 업데이트 된 후에 환불됩니다."
    },
    "getRegisterPoint": "회원가입 하고 {amount} {points_name} 을 적립"
  },
  "progressBar": {
    "completeLottery": "추첨 응모 완료",
    "completeLotterySubtitle": "추첨 응모가 완료되었습니다.",
    "confirmationSubtitle": "객실 예약이 완료되었습니다.",
    "confirmationSubtitleOTA": "객실 예약이 완료되었습니다.",
    "confirmationTitle": "예약확정",
    "confirmationTitleOTA": "예약 완료",
    "guestSubtitle": "고객 정보를 입력하십시오",
    "guestTitle": "고객 정보",
    "paymentSubtitle": "결제방법 선택",
    "paymentTitle": "결제"
  },
  "reservation": {
    "cancelled": "취소 완료",
    "completed": "숙박 완료",
    "upcoming": "예약중",
    "lottery": "추첨",
    "filtersLabel": "필터",
    "filters": {
      "all": "모두",
      "onSiteChecked": "현지결제",
      "on_site_credit_card_required": "현지결제",
      "otaPrePaidChecked": "{0}를 통한 신용카드 결제",
      "bookingWidgetPrePaidChecked": "신용카드 온라인사전결제",
      "deferredPaymentChecked": "후불"
    },
    "reservedChannel": {
      "all": "모두",
      "title": "예약 경로",
      "officialWebsite": "공식 사이트 (현지결제)",
      "wbf": "다이나믹 패키지",
      "tripla": "여행 예약 사이트 (신용카드 사전결제)"
    },
    "signInUpReminder": "로그인/회원등록으로 회원 특전 받기"
  },
  "reservationAction": {
    "contactTriplaSupport": "tripla 지원팀에 문의",
    "applyGOTO": "쿠폰 코드를 적용",
    "bookingCancel": "예약을 취소",
    "bookingUpdate": "고객정보를 변경",
    "corporateSignInRequired": "예약을 변경하시려면 법인(회사) 로그인이 필요합니다",
    "differentFacilityWarning": "예약을 변경하시려면 예약하신 호텔의 페이지로 로그인하십시오.",
    "everythingCorrectSubtitle": "아래에서 해당 예약의・고객정보를 변경 및 취소하실 수 있습니다.",
    "everythingCorrectTitle": "예약관리",
    "invoiceDownload": "상세내용 인쇄",
    "madeMistakeHelp": "다음에 다시 뵙겠습니다!",
    "madeMistakeTitle": "예약이 취소되었습니다.",
    "memberSignInRequired": "예약을 변경하시려면 멤버십 로그인이 필요합니다",
    "pleaseCancelAll": "모든 객실의 취소를 원하시면 예약을 취소해 주십시오.",
    "payByCreditCard": "신용카드로 결제",
    "reductionOfNights": "투숙기간 단축",
    "reductionOfRooms": "객실 일부 취소",
    "selectRoomsToCancel": "취소할 객실을 선택해 주십시오",
    "signOutRequired": "예약을 변경하시려면 로그아웃이 필요합니다",
    "updateExpired": "본 예약은 변경 기한이 지난 관계로 예약 변경이 불가능합니다.",
    "updateItinerary": "예약 변경",
    "differentAccountWarning": "올바른 계정으로 로그인하여 예약을 업데이트하십시오.",
    "differentOrgWarning": "올바른 법인(회사) 계정으로 로그인하여 예약을 업데이트하세요.",
    "coupon": {
      "signOutRequired": "쿠폰 적용을 위해 로그아웃 하세요. ",
      "memberSignInRequired": "쿠폰을 적용하려면 멤버십 등록이 필요합니다.",
      "corporateSignInRequired": "쿠폰을 적용하려면 법인(회사) 로그인이 필요합니다.",
      "differentFacilityWarning": "쿠폰을 적용하려면 예약한 호텔의 페이지에서 로그인하세요.",
      "differentAccountWarning": "쿠폰을 적용하려면 올바른 계정으로 로그인하세요. ",
      "differentOrgWarning": "쿠폰을 적용하려면 올바른 법인(회사)계정으로 로그인 하십시오."
    },
    "notEnoughStays": "선택한 숙박 기간은 해당 예약의 최소 숙박 기간({0}박)과 같거나 그 이상이어야 합니다.\n",
    "viewMyReservations": "나의 예약 확인하기",
    "madeMistakeHelp2": "다음에 또 이용해 주시기 바랍니다.",
    "madeMistakeTitle2": "예약이 취소되었습니다."
  },
  "reservationCancellation": {
    "roomN": "객실 {0}",
    "cancellationFees": "취소료",
    "cancellationConfirm2": "예약을 취소하시겠습니까?",
    "reason2": "취소 사유를 알려주세요.",
    "reasonTripCancelled2": "여행 계획 변경",
    "reasonRoomCheaper2": "가격이 더 저렴한 타사 이용 예정",
    "reasonNbTrvellers2": "인원수 변경",
    "reasonDifferentHotel2": "다른 호텔 이용 예정",
    "reasonOther2": "기타 (구체적으로 작성해 주세요)",
    "cancel": "예약 취소",
    "cancellationConfirm": "정말 예약을 취소 하시겠습니까?",
    "cancellationFee": "취소료",
    "cancellationOnDay": "온라인상에서는 당일 취소가 불가능합니다.",
    "cancellationPolicy": "취소 규정",
    "cancellationTitle": "예약취소",
    "contactByChatPhone": "전화로 연락해 주시기 바랍니다.",
    "contactByPhone": "전화로 연락해 주시기 바랍니다.",
    "contactPhone": "전화번호 {0}",
    "keep": "예약 유지",
    "nonRefundable": "환불불가",
    "reason": "취소 이유를 알려주시기 바랍니다.",
    "reasonDifferentHotel": "다른 호텔 이용 예정",
    "reasonNbTrvellers": "인원수 변경",
    "reasonOther": "기타",
    "reasonOtherHelp": "기타 다른 사유가 있으시면 알려주시면 감사하겠습니다.",
    "reasonRoomCheaper": "가격이 더 저렴한 타사 이용 예정",
    "reasonTripCancelled": "여행 계획 변경",
    "roomNbCancelFee": "객실 {0} 취소료",
    "totalCancelFee": "전체 취소료",
    "changePlanQuestion": "플랜을 변경해야 하나요?",
    "changePlanDesc": "예약을 취소하는 대신, 일정에 더 알맞는 예약으로 변경할 수 있습니다. 예약을 유연하게 조정하여 숙박을 더욱 편리하게 즐기세요.",
    "changePlanWarnTL": "기존 예약의 플랜은 변경이 어렵습니다.",
    "cancelTitle": "예약취소",
    "cancellationNote": "취소 수수료가 부과될 수 있습니다.",
    "cancellationReason": "취소 사유:",
    "cancellationTellUs": "취소하는 이유를 알려주세요:",
    "reasonPlanChange": "여행 계획 변경",
    "reasonAffordableOption": "더 합리적인 옵션 발견",
    "reasonNumberAdjustment": "여행자 수 조정",
    "reasonHotelAlt": "대체 호텔 선택",
    "reasonOthers": "상세내용"
  },
  "reservationConfirmation": {
    "bookingConfirmed": "예약이 확정되었습니다.",
    "cancelledSuccessfully": "예약이 정상적으로 취소되었습니다.",
    "totalStays": "투숙일 수",
    "contactProperty": "연락처",
    "yourRoomDetails": "선택하신 객실 정보",
    "phone": "전화번호",
    "email": "이메일 주소",
    "website": "웹사이트",
    "cancellationFreeUntil": "{0} 까지 무료 취소",
    "cannotChangePlan": "기존 예약에 포함된 숙박 플랜은 변경이 불가능합니다.",
    "confirmationEmail": "예약확정 이메일이 {0}로 전송되었습니다.",
    "extra": {
      "answer": "답변",
      "dateTime": "이용일시",
      "extrasPrice": "기타옵션",
      "items": "선택 수량",
      "notes": "알림",
      "price": "가격",
      "priceIncludeTax": "요금(세금포함)",
      "question": "질문",
      "roomPrice": "전체 객실요금"
    },
    "extrasSubtotal": "기타옵션 전체",
    "nonRefundableReminder": "본 예약은 환불불가 상품입니다. 예약 확정 후에는 환불이 불가함을 인지해 주시기 바랍니다.",
    "originalReservationPlans": "기존 예약에 포함된 숙박 플랜.",
    "reservationDetail": "예약 내용",
    "selectNewItinerary": "변경 원하시는 숙박기간과 인원수를 선택하시기 바랍니다.",
    "updateBooking": "예약 변경",
    "cannotUpdateTooltip": "예약 내용 및 요금은 고객의 동의에 따라 시설 직원에 의해 사전에 변경되었으므로 예약을 업데이트/취소할 수 없습니다."
  },
  "reservationDetail": {
    "bookingDetails": "예약 상세 ",
    "adult": "{n}명",
    "accommodationTax": "숙박세",
    "checkIn": "체크인 날짜",
    "checkOut": "체크아웃 날짜",
    "child": "{n}명",
    "dayUse": "데이 유즈",
    "details": "세부 정보",
    "detailsTitle": "예약 내용",
    "discount": "할인",
    "discounts": "할인",
    "discountedPrice": "포인트 할인",
    "guest": "{n}명",
    "guests": "투숙 인원수",
    "hotel": "호텔",
    "maxGuests": "최대 정원",
    "night": "{n}박",
    "nights": "투숙일 수",
    "numberOfGuest": "객실수",
    "numberOfNights": "투숙일 수",
    "numberOfPeople": "인원수",
    "numberOfAdults": "성인 {n}명",
    "numberOfChildren": "어린이 {n}명",
    "originalPrice": "전체 결제요금",
    "pointDiscount": "포인트 할인금액",
    "pointInstruction": "포인트는 체크아웃 이후 3일 후 적립됩니다.",
    "pointReward": "이 예약으로 {0}포인트가 지급됩니다.",
    "priceBreakDown": "요금 세부사항",
    "priceDetails": "요금 세부사항",
    "reservationDetails": "예약 내용",
    "reservationNumber": "예약 번호",
    "roomDetails": "객실 정보",
    "roomNb": "객실 {0}",
    "roomPlan": "플랜명",
    "roomRate": "객실 요금",
    "roomType": "객실 타입",
    "roomWithChildren": "성인 {nAdult} 명과 어린이 {nChild} 명을 위한 {nRoom} 개의 객실",
    "roomWithoutChildren": "성인 {nAdult} 명을 위한 {nRoom} 개의 객실",
    "searchNecessary": "*해당 예약번호는 변경 또는 취소 시 호텔로 직접 문의하셔야 합니다.",
    "serviceFee": "서비스요금",
    "selected": "선택 결과",
    "subTotal": "전체",
    "subTotalWithoutTax": "전체 (세금별도)",
    "tax": "세금",
    "taxIncluded": "세금포함",
    "total": "결제금액(세금포함)",
    "totalAmount": "합계 금액",
    "totalLengthOfStay": "투숙일 수",
    "totalPriceWithTax": "세금 포함 총 금액",
    "depositAmount": "보증금 ({0}%)",
    "remainAmount": "잔여금액",
    "withTax": "세금포함",
    "depositNecessary": "예약을 확정하시려면 온라인 시스템을 통해 {0}%의 보증금을 지불하셔야 합니다."
  },
  "reservationPayment": {
    "confirmPriceChanged": "",
    "creditCardPayment": "신용카드 결제",
    "onSitePayment": "현지결제(사전결제 불필요)",
    "paymentMethod": "지불방법",
    "pendingPage": {
      "launchButton": "3D Secure",
      "text01": "버튼을 클릭하시면 3D Secure 해외안심결제 비밀번호 입력화면으로 이동합니다.",
      "text02": "15분 내에 비밀번호 입력을 완료하지 않으시면 예약이 취소됩니다.",
      "text03": "예약은 비밀번호를 입력하실때까지 확정되지 않습니다. 현재 가능한 객실이 다른 채널을 통해 판매될 수 있으므로 이전에 확정해 주시기 바랍니다.",
      "text04": "신용카드 결제는 tripla가 대행하기 때문에 3D Secure 안심결제의 상점명은 「tripla」라고 표시됩니다.",
      "timeOutError01": "시간초과 에러",
      "timeOutError02": "예약이 완료되지 않은 관계로 요금이 청구되지 않습니다. 혹여 요금이 청구될 경우에는 환불 절차가 진행됩니다. 환불 일정에 대해서는 카드사에 직접 문의하시기 바랍니다.\r",
      "authError01": "결제 처리에 실패하였습니다.",
      "error01": "예약 처리중에 에러가 발생하였습니다.",
      "error02": "예약이 완료되지 않은 관계로 요금이 청구되지 않습니다. 혹여 요금이 청구될 경우에는 환불 절차가 진행됩니다. 환불 일정에 대해서는 카드사에 직접 문의하시기 바랍니다.\r",
      "error03": "「돌아가기」버튼을 클릭하셔서, 검색 결과 화면에서 예약을 다시 진행해 주시기 바랍니다."
    },
    "planError": "죄송합니다. 결제유형 중복이 있습니다. 다시 시도해 주시기 바랍니다.",
    "selectCreditCards": "신용카드를 선택하십시오",
    "sessionErrorMessage": "문제가 발생했습니다. 고객 정보 페이지로 돌아가서 다시 시도해 주세요.",
    "sessionErrorButtonText": "고객 정보로 돌아가기",
    "gmoCreditCardMaintenanceWarning": "2024/11/18 월요일 오전 4시~6시 (일본시간) 시스템 점검으로 인해, 이 카드 브랜드는 이용이 불가합니다. 다른 결제 수단을 선택하거나, 점검이 완료될 때까지 기다려 주세요.",
    "gmoOnSiteCreditCardRequiredMaintenanceWarning": "2024/11/18 월요일 오전 4시~6시 (일본시간) 시스템 점검으로 인해, 카드 등록 이용이 불가합니다. 다른 결제 수단을 선택하거나, 점검이 완료될 때까지 기다려 주세요.",
    "gmoMcpMaintenanceWarning": "2024/11/18 월요일 오전 4시~6시 (일본시간) 시스템 유지 보수로 인해, VISA/Mastercard 이용이 불가합니다. 다른 결제 수단을 선택하거나, 점검이 완료될 때까지 기다려 주세요."
  },
  "reservationReceipt": {
    "customerName": "투숙객 이름",
    "deferredWithoutDownload": "후불 정산이기 때문에 영수증이 발행되지 않습니다.",
    "download": "영수증 다운로드",
    "downloadOnCheckout": "영수증은 체크아웃날짜 이후에 다운로드 가능합니다.",
    "downloadAtSpecificTime": "영수증은 {date} {time} 에 다운로드하실 수 있으며, 이후에도 언제든지 가능합니다.",
    "onSite": "현지 결제의 경우 영수증은 호텔 프론트데스크에서 발행해 드립니다."
  },
  "searchBar": {
    "addRoom": "객실 추가",
    "adult": "성인 {n}명",
    "adults": "성인 인원수",
    "age": "나이",
    "agree": "동의",
    "bed": "침대",
    "checkIn": "체크인",
    "checkInAndOut": "체크인날짜 - 체크아웃날짜",
    "checkOut": "체크아웃",
    "child": "{n}어린이",
    "childN": "어린이{n}",
    "children": "어린이",
    "childrenAgeAtCheckout": "어린이 나이",
    "childrenAgeInfant": "나이 {from}-{to}",
    "childrenAgePrimary": {
      "primary": "나이 {from}-{to}",
      "primaryBottom": "나이 {from}-{to}",
      "primaryTop": "나이 {from}-{to}"
    },
    "childrenAges": "동반하는 어린이 나이를 알려주시기 바랍니다.",
    "childrenAgeSharedBed": "나이 {from}-{to}",
    "childrenMealBedding": {
      "meal_no": "식사・이불제외",
      "meal_no_bedding": "이불포함",
      "meal_same_as_adult_bedding": "식사・이불포함",
      "meal_yes": "식사포함",
      "meal_yes_bedding": "식사・이불포함"
    },
    "cookieConsent": "해당 웹사이트는 사용자 경험을 개선하기 위해 쿠키를 사용합니다. 해당 웹사이트를 계속 사용함으로써 사용자는 당사의 쿠키 동의 정책에 동의합니다. 자세한 내용은 {0}을 확인해 주시기 바랍니다.",
    "privacyPolicy": "개인보호정책",
    "perRoomGuests": "객실당 투숙가능 인원 수",
    "dates": "날짜",
    "dayUse": "당일 이용 플랜을 검색",
    "defaultArea": "전 지역",
    "edit": "수정",
    "filter": "필터",
    "guests": "고객수",
    "hotel": "호텔",
    "maxChildrenWarning": "성인 1인당 가능 어린이 수는 {n}명입니다.",
    "pleaseSelectHotel": "호텔을 선택해 주세요.",
    "room": "{n}객실",
    "rooms": "객실수",
    "search": "검색",
    "showOnlyAvailable": "예약 가능내용만 보기",
    "sort": "정렬",
    "sortOption": {
      "price_high_to_low": "높은 가격순",
      "price_low_to_high": "낮은 가격순",
      "recommended": "추천순",
      "asc": "낮은 가격순",
      "desc": "높은 가격순"
    },
    "to": "~",
    "undated": "날짜 미정",
    "nights": "투숙일 수",
    "yearOld": "{n}살",
    "enableWBFSearch": "숙박 + 항공권 패키지 검색 활성화",
    "stayFlightTab": "숙박 + 항공권",
    "stayTab": "숙박",
    "processInJapTxt": "예약 절차는 일본어로만 가능합니다.",
    "processMaxTxt": "예약 절차는 일본어로만 가능하며 숙박 + 항공권 예약은 최대 5개 객실까지 가능합니다.",
    "undatedWarning": "객실마다 인원수가 다른 경우에는 날짜 미정 검색을 이용할 수 없습니다",
    "undatedWarning2": "「항공권+객실」로 검색 시에는 이용할 수 없습니다",
    "flightAndRoomsWarning": "「날짜 미정」으로 검색 시에는 이용할 수 없습니다"
  },
  "searchReservation": {
    "enterDetail": "예약번호와 이메일주소를 입력해주시기 바랍니다.",
    "enterReservationNumber": "예약번호를 입력해주시기 바랍니다.",
    "manageBooking": "예약 관리",
    "searchFailed": "이메일 또는 예약번호가 확인되지 않습니다. 다시 시도해주시기 바랍니다.",
    "confirmReservation": "예약 확인",
    "confirmReservationDesc": "이 예약은 {ota} 를 통해 결제되므로 {time}초 후 {ota}로 되돌아갑니다."
  },
  "searchResultByPlan": {
    "hide": "숨기기",
    "planContent": "플랜 내용",
    "plans": "플랜",
    "rooms": "객실",
    "showPlan": "플랜 내용을 표시",
    "moreDetail": "더 보기"
  },
  "snsLogin": {
    "loginWith": "{0}로 로그인",
    "enterPassword": "계속하시려면 비밀번호를 입력해 주십시오",
    "existingPrompt": "{sns} 계정을 가지고 계신가요? {sns} 계정과 이메일 /비밀번호로 로그인 하실 수 있습니다.",
    "signUpDescription": "{sns} 계정으로 접속해 주셔서 감사합니다. 회원 가입을 위해 나머지 정보를 입력해 주시기 바랍니다."
  },
  "voucher": {
    "cancel": "변경 취소",
    "confirm": "확정",
    "number": "바우처 번호",
    "purchseComplete": "구입완료",
    "selectStay": "체크인 날짜를 선택해 주세요.",
    "stillVoucher": "이것은 숙박 바우처입니다. 아직 정식 예약으로서의 체크인/아웃 날짜는 지정되어 있지 않습니다.",
    "thankPurchase": "구입해주셔서 감사합니다.",
    "update": "변경확인",
    "updateItinerary": "예약변경",
    "updateItineraryDesc": "새로운 숙박일정을 지정하세요.「변경확정」버튼을 클릭하면 변경이 확정됩니다.",
    "usingVoucher": "바우처로 객실 예약",
    "valid": "사용 가능한 바우처입니다.",
    "voucherDesc": "여기에서 체크인/체크아웃 날짜를 지정하시면 바우처로 객실을 예약하실 수 있습니다.",
    "voucherDetails": "바우처 상세내용",
    "voucherNumber": "바우처 번호"
  },
  "triplaPay": {
    "notFoundMsg": "결제 기록을 찾을 수 없습니다. 거래가 완료되지 않습니다.",
    "isPaid": "결제가 성공적으로 완료되었습니다",
    "isAlreadyPaid": "지불완료",
    "isFailed": "결제실패",
    "isCompleted": "결제가 성공적으로 완료되었습니다",
    "isRefunded": "결제 금액이 환불되었습니다.",
    "refundedAt": "환불 날짜",
    "paidAt": "결제일",
    "isCancelled": "결제가 취소되었습니다",
    "total": "결제금액",
    "pleaseReload": "해당 작업은 1시간 이내에 완료되지 않았습니다. 새로고침 해주세요.",
    "reload": "새로고침",
    "subject": "지불 대상",
    "amountIsChanged": "결제 금액이 변경되었으므로 새로고침 해주세요.",
    "terms": "본 결제 시스템은 tripla(주)에 의해 제공되며 고객의 개인정보는 tripla(주)와 공유됨을 이해하며 tripla {0}, {1}, {2}에 동의합니다.",
    "paypalTerms": "또한 카드 결제에는 PayPal을 이용하고 있으며, 카드의 이용 명세서에는 PayPal로 기재됩니다.",
    "temsOfUse": "이용약관",
    "privacyPolicy": "개인정보 보호법",
    "gdprPolicy": "개인정보 보호규정",
    "pleaseScan": "QR코드를 스캔해주세요"
  },
  "triplaPayIssues": {
    "INVALID_PARAMETER_SYNTAX": "입력한 정보를 확인하세요.",
    "INVALID_EXPIRATION_YEAR": "만료일이 올바른지 확인하십시오.",
    "Invalid_card_number": "카드번호를 확인바랍니다.",
    "Others": "입력한 정보를 확인하세요."
  },
  "lottery": {
    "registerForLottery": "추첨 등록하기",
    "selectedDatesError": "선택한 숙박 기간은 추첨 이벤트가 설정한 최대 및 최소 숙박 기간 이내여야 합니다. ( {0} ~ {1} 박)"
  },
  "recommenderWidget": {
    "previewText": "이것은 미리보기 모드이며 5분간만 유효합니다. 5분 후에 '미리보기'를 다시 클릭하세요. 실제 라이브 버전에는 설정을 저장해야 합니다."
  }
}