import API from './fetch'
import { cancellationPolicyURL } from './url'
import { generateParamsURL } from 'utilities/paramsFormat'
import _find from 'lodash/find'
import _reject from 'lodash/reject'
import _sortBy from 'lodash/sortBy'
import i18n from '../i18n'
import { orderBy } from 'lodash'

const CancellationPolicies = {
  indexApi(query = {}, hotelId) {
    return API.getData(
      generateParamsURL(cancellationPolicyURL(hotelId), query, ['room_plan_codes'])
    )
  },
  get(id, hotelId) {
    return API.getData(`${cancellationPolicyURL(hotelId)}/${id}`).then((res) => {
      const {
        cancel_before_at: cancelDeadTime,
        cancellation_policy_parts,
        no_show_percentage_of_cost
      } = res.data

      const sortedParts = _sortBy(cancellation_policy_parts, 'days_before').reverse()
      const cancelRateOnTheDay = _find(sortedParts, { days_before: 0 })?.percentage_of_cost ?? 100

      const filteredParts = _reject(sortedParts, { days_before: 0 })
      const chargeableParts = orderBy(
        _reject(filteredParts, { percentage_of_cost: 0 }),
        ['days_before', 'hours_after'],
        ['desc', 'asc']
      )

      const freeDayThreshold = (sortedParts[0]?.days_before || 0) + 1

      const onTheDayParts = orderBy(
        _reject(sortedParts, (part) => part.days_before > 0),
        ['days_before', 'hours_after'],
        ['desc', 'asc']
      )

      const generateCancellationLines = () => {
        const lines = [i18n.t('cancellationPolicy.policyHeader')]

        if (chargeableParts[0]?.hours_after > 0) {
          lines.push('')
        } else {
          lines.push(
            sortedParts.length === 1 && cancelDeadTime > 0
              ? i18n.t('cancellationPolicy.cancelOnTheDayFree', [`${cancelDeadTime}:00`])
              : i18n.t('cancellationPolicy.freePolicyLine', [freeDayThreshold])
          )
        }

        if (chargeableParts[0]?.hours_after > 0) {
          lines.push(
            i18n.t('cancellationPolicy.policyWithTimeEachDayFree', [
              chargeableParts[0].days_before,
              `${chargeableParts[0].hours_after}:00`
            ])
          )
        }

        lines.push(
          ...chargeableParts.map((part) =>
            part.hours_after > 0
              ? i18n.t('cancellationPolicy.policyWithTimeEachDayCharge', [
                  part.days_before,
                  `${part.hours_after}:00`,
                  part.percentage_of_cost
                ])
              : i18n.t('cancellationPolicy.policyLine', [part.days_before, part.percentage_of_cost])
          )
        )

        if (onTheDayParts[0]?.hours_after > 0) {
          lines.push(
            chargeableParts.length > 0
              ? i18n.t('cancellationPolicy.cancelBeforeOnTheDayCharge', [
                  `${onTheDayParts[0].hours_after - 1}:59`,
                  chargeableParts.at(-1).percentage_of_cost
                ])
              : i18n.t('cancellationPolicy.cancelOnTheDayFree', [
                  `${onTheDayParts[0].hours_after}:00`
                ])
          )
        }

        lines.push(
          ...onTheDayParts.map((part) =>
            onTheDayParts.length > 1 && part.hours_after > 0
              ? i18n.t('cancellationPolicy.cancelOnTheDayCharge', [
                  `${part.hours_after}:00`,
                  part.percentage_of_cost
                ])
              : ''
          )
        )

        if (onTheDayParts.length === 1) {
          lines.push(
            onTheDayParts[0].hours_after > 0
              ? i18n.t('cancellationPolicy.cancelOnTheDayCharge', [
                  `${onTheDayParts[0].hours_after}:00`,
                  onTheDayParts[0].percentage_of_cost
                ])
              : i18n.t('cancellationPolicy.cancelOnTheDay', [cancelRateOnTheDay])
          )
        }

        lines.push(i18n.t('cancellationPolicy.noShowLine', [no_show_percentage_of_cost]))
        return lines
      }

      return {
        ...res,
        lines: generateCancellationLines()
      }
    })
  }
}

export default CancellationPolicies
