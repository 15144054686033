<template>
  <footer>
    <customized-footer v-if="displayFooter && !$route.meta.headless" />
    <portal-target name="BwFooterExtraCopyRights" />
  </footer>
</template>

<script>
import CustomizedFooter from 'components/CustomizedFooter'
import { mapGetters } from 'vuex'

export default {
  name: 'BwFooter',

  components: { CustomizedFooter },

  computed: {
    ...mapGetters({
      displayFooter: 'setting/getFooter'
    })
  }
}
</script>
